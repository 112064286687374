// Here you can add other styles
.hidden {
  display: none;
}
.MUIDataTable-responsiveBase-5 {
  overflow: unset !important ;
}

// Font-Family
.fredoka {
  font-family: 'Fredoka', sans-serif;
  font-weight: 300;
}
.ck-editor__editable {
  min-height: 300px;
}

.fredoka-400 {
  font-family: 'Fredoka', sans-serif;
  font-weight: 400;
}

.fredoka-500 {
  font-family: 'Fredoka', sans-serif;
  font-weight: 500;
}

.fredoka-600 {
  font-family: 'Fredoka', sans-serif;
  font-weight: 600;
}

.fredoka-700 {
  font-family: 'Fredoka', sans-serif;
  font-weight: 700;
}

// Margins
.ml-auto {
  margin-left: auto;
}
.ml-right {
  margin-right: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
